<template>
  <div class="settlein" :style="_isMobile() ? 'overflow:auto' :''">
    <!-- <SettleIntop /> -->
    <div class="top_view_ min-border-bottom flex j-center  a-center" :style="_isMobile() ? 'width:500px' :''">
      <div class="left flex j-end a-center">
        <div class="image_veiw" />
      </div>
      <div class="flex a-cenetr right j-end  p-right-30">
        <span>已有账号，立刻去</span>
        <span class="theme-color  p-left-5 cursor" @click="login">登录 <span class="iconfont icon-youjiantou" /></span>
      </div>
    </div>
    <div class="main_set" :style="_isMobile() ? 'width:500px' :''">
      <!-- 入驻表单  style="min-width:800px" -->
      <div class="main m-top-20">
        <div class="title theme-color weight p-left-20" :style="_isMobile() ? 'margin-left:70px' :''">
          企业信息及联系人信息
        </div>
        <div class="form">
          <el-form ref="form" class=" m-tb-20" :rules="rules" :model="form" label-width="150px">
            <el-form-item label="公司名称：" prop="CompanyName" :style="_isMobile() ? 'width:450px' :''">
              <el-input v-model="form.CompanyName" style=" width: 70%;" size="mini" class="form_settlein" />
              <!-- <span class="cursor theme-color p-left-20" @click="() => $router.push({name:'RetrieveNumber'})">找回账号</span> -->
            </el-form-item>
            <el-form-item label="社会统一信用代码：" prop="SAPSTCD5" :style="_isMobile() ? 'width:450px' :''">
              <el-input v-model="form.SAPSTCD5" style=" width: 70%;" size="mini" class="form_settlein" />
            </el-form-item>
            <el-form-item label="证件有效期：" prop="Date">
              <el-date-picker
                v-model="form.Date"
                size="mini"
                :disabled="form.YYZZIsLong ? true :false"
                :unlink-panels="true"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              />
              <!-- <el-date-picker
                v-model="form.YYZZStartDate"
                type="datetime"
                size="mini"
                value-format="yyyy-MM-dd"
                placeholder="开始时间"
              />
              ——
              <el-date-picker
                v-model="form.YYZZEndDate"
                size="mini"
                type="datetime"
                value-format="yyyy-MM-dd"
                placeholder="结束时间"
              /> -->
              <el-checkbox v-model="form.YYZZIsLong" class="p-left-10" @change="isLong">长期有效</el-checkbox>
            </el-form-item>
            <el-form-item label="公司性质：" :style="_isMobile() ? 'width:500px' :''" prop="CompanyTypeID">
              <el-radio-group v-model="form.CompanyTypeID">
                <el-radio :label="1">买家</el-radio>
                <el-radio :label="4">卖家（供应商）</el-radio>
              </el-radio-group>
              <el-select v-if="form.CompanyTypeID === 4" v-model="form.BusinessScope" size="mini" placeholder="请选择供应商类别" @change="isChangeValue">
                <el-option
                  v-for="item in options"
                  :key="item.IDX"
                  :label="item.Name"
                  :value="item.IDX"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="联系人：" :style="_isMobile() ? 'width:450px' :''" prop="AuthUserName">
              <el-input v-model="form.AuthUserName" style=" width: 70%;" size="mini" class="form_settlein" />
            </el-form-item>
            <el-form-item label="手机号：" prop="PhoneNumber" :style="_isMobile() ? 'width:450px' :''">
              <el-input v-model="form.PhoneNumber" style=" width: 70%;" size="mini" class="form_settlein" />
            </el-form-item>
            <el-form-item label="校验码：" prop="code" :style="_isMobile() ? 'width:450px' :''">
              <div class="flex">
                <el-input v-model="form.code" type="number" style=" width: 70%;" size="mini" class="form_settlein" />
                <div v-show="code_img !==null && code_img.length > 0" style="height:5px;margin-top:7px" class=" cursor m-left-20">
                  <!-- <img class="code_img cursor" :src="code_img" @click="changeImg"> -->
                  <img id="code_img" class="code_img cursor" :src="code_img" style="display:none">
                  <canvas id="myCanvas" width="80" height="22" @click="changeImg" />
                </div>
              </div>
            </el-form-item>
            <el-form-item label="短信验证码：" prop="PhoneValid" :style="_isMobile() ? 'width:600px' :''">
              <el-input v-model="form.PhoneValid" type="number" :style="_isMobile() ? ' width: 47%;' :'width: 70%;'" size="mini" class="form_settlein m-right-20" />
              <el-button type="primary" size="mini" @click="SendPhoneValidataCode">

                {{ codeNumLast > 0 ? '重新获取  ' + codeNumLast :'获取验证码' }}
              </el-button>
            </el-form-item>
            <el-form-item label="单位地址：" prop="selectedOptions" :style="_isMobile() ? 'width:600px' :''">
              <div class="flex a-center">
                <el-cascader
                  v-model="form.selectedOptions"
                  size="mini"
                  :options="options1"
                  style="width: 290px"
                  @change="handleChange"
                />
              </div>
            </el-form-item>
            <el-form-item label="详细地址：" prop="Adress" :style="_isMobile() ? 'width:450px' :''">
              <el-input v-model="form.Adress" size="mini" style=" width: 70%;" class="form_settlein" />
            </el-form-item>
            <!-- 营业执照 -->
            <el-form-item :label="form.CompanyTypeID === 4 ? '营业执照/医疗机构执业许可证：' :'营业执照：' " prop="YYZHUrl">
              <el-input v-model="form.YYZHUrl" style="display:none" />
              <div class="flex">
                <el-upload
                  ref="uploadUrlRF"
                  class="upload-demo"
                  :show-file-list="false"
                  drag
                  :data="fileData"
                  :before-upload="beforeUpload"
                  action="/api/UploadRegistCertificate"
                  :http-request="uploadFile"
                  accept=".jpg,.png,.jpeg,.gif"
                  multiple
                  :on-exceed="onExceed"
                  name="Base64String"
                >
                  <i class="el-icon-upload" />
                  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                  <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过5M</div>

                </el-upload>
                <div class="p-lr-20" />
                <el-image
                  v-if="form.YYZHUrl"
                  fit="contain"
                  style="width:200px;height:200px;min-width:100px"
                  :src="form.YYZHUrl"
                  @click="() => showViewer = true"
                />
              </div>
            </el-form-item>
            <!-- 医疗器械经营许可证 1-->
            <!-- <el-form-item v-if="form.CompanyTypeID === 4 && form.BusinessScope === -2 && form.BusinessScope === -3 && form.BusinessScope === 1 && form.BusinessScope === 2 && form.BusinessScope === 3" prop="urlph" :label="label_value"> -->
            <el-form-item v-if="form.CompanyTypeID === 4 && form.BusinessScope !== 4 && form.BusinessScope !== -1 && form.BusinessScope !== 6 && form.BusinessScope !== ''" prop="urlph" :label="label_value">
              <el-input v-model="form.urlph" style="display:none" />
              <div class="flex">
                <el-upload
                  ref="uploadUrlRF1"
                  class="upload-demo"
                  :show-file-list="false"
                  drag
                  :data="fileData"
                  :before-upload="beforeUpload"
                  action="/api/UploadRegistCertificate"
                  :http-request="uploadFile1"
                  accept=".jpg,.png,.jpeg,.gif"
                  multiple
                  :on-exceed="onExceed"
                  name="Base64String"
                >
                  <i class="el-icon-upload" />
                  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                  <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过5M</div>
                </el-upload>
                <div class="p-lr-20" />
                <el-image
                  v-if="form.urlph"
                  fit="contain"
                  style="width:200px;height:200px;min-width:100px"
                  :src="form.urlph"
                  @click="() => showViewer1 = true"
                />
              </div>
            </el-form-item>
            <!-- 药品生产许可证 2-->
            <el-form-item v-if="form.CompanyTypeID === 4 && form.BusinessScope === 4" prop="yaopxk" label="药品生产/经营许可证：">
              <el-input v-model="form.yaopxk" style="display:none" />
              <div class="flex">
                <el-upload
                  ref="uploadUrlRF2"
                  class="upload-demo"
                  :show-file-list="false"
                  drag
                  :data="fileData"
                  :before-upload="beforeUpload"
                  action="/api/UploadRegistCertificate"
                  :http-request="uploadFile2"
                  accept=".jpg,.png,.jpeg,.gif"
                  multiple
                  :on-exceed="onExceed"
                  name="Base64String"
                >
                  <i class="el-icon-upload" />
                  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                  <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过5M</div>
                </el-upload>
                <div class="p-lr-20" />
                <el-image
                  v-if="form.yaopxk"
                  fit="contain"
                  style="width:200px;height:200px;min-width:100px"
                  :src="form.yaopxk"
                  @click="() => showViewer2 = true"
                />
              </div>
            </el-form-item>
            <!-- 药品GMP证书 3-->
            <!-- <el-form-item v-if="form.CompanyTypeID === 4 && form.BusinessScope === 4" prop="gmp" label="药品GMP证书：">
              <el-input v-model="form.gmp" style="display:none" />
              <div class="flex">
                <el-upload
                  ref="uploadUrlRF3"
                  class="upload-demo"
                  :show-file-list="false"
                  drag
                  :data="fileData"
                  :before-upload="beforeUpload"
                  action="/api/UploadRegistCertificate"
                  :http-request="uploadFile3"
                  accept=".jpg,.png,.jpeg,.gif"
                  multiple
                  :on-exceed="onExceed"
                  name="Base64String"
                >
                  <i class="el-icon-upload" />
                  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                  <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过5M</div>
                </el-upload>
                <div class="p-lr-20" />
                <el-image
                  v-if="form.gmp"
                  fit="contain"
                  style="width:200px;height:200px;min-width:100px"
                  :src="form.gmp"
                  @click="() => showViewer3 = true"
                />
              </div>
            </el-form-item> -->
            <el-form-item label="">
              <div class="flex column f-start a-start">
                <span class="f12 weight" style="color:#ccc">注：上传后再次上传均为覆盖原图</span>
                <span class="f12 weight" style="color:#ccc">注：所有资料均需要加盖商家公章</span>
              </div>

            </el-form-item>
            <el-form-item label="备注：" :style="_isMobile() ? 'width:800px' :''">
              <el-input v-model="form.Remark" style=" width: 20%;" size="mini" placeholder="请输入备注信息" class="form_settlein" />
            </el-form-item>
            <el-form-item label="" prop="checked" :style="_isMobile() ? 'width:800px' :''">
              <el-checkbox v-model="form.checked" />
              我同意并遵守<span class="theme-color cursor" @click="viewdoc">《平台服务协议》</span>
            </el-form-item>
            <!-- <el-form-item label="">
              <div style="width；100%" class="flex j-center">
                <div class="btn f12 cursor">入驻并等待审核</div></div>
            </el-form-item> -->
          </el-form>
        </div>
        <div style="widt:100%" class="flex j-center m-bottom-30">
          <el-button type="primary" :loading="loading" style="backgroundColor:#3e76ed;;border:none" size="mini" :disabled="statusCode === '-1' ? true: false" round @click="allin('form')">入驻并等待审核</el-button>
          <!-- <div class="btn f12 cursor" @click="allin('form')">入驻并等待审核</div> -->
        </div>
      </div>
    </div>
    <div class="foot123">
      <!-- :style="_isMobile() ? 'min-width:100%' :'width:100%'" -->
      <SettleInFoot />
    </div>
    <el-image-viewer
      v-if="showViewer"
      style="z-index: 9999"
      :url-list="[form.YYZHUrl]"
      :on-close="() => (showViewer = false)"
    />
    <el-image-viewer
      v-if="showViewer1"
      style="z-index: 9999"
      :url-list="[form.urlph]"
      :on-close="() => (showViewer1 = false)"
    />
    <el-image-viewer
      v-if="showViewer2"
      style="z-index: 9999"
      :url-list="[form.yaopxk]"
      :on-close="() => (showViewer2 = false)"
    />
    <el-image-viewer
      v-if="showViewer3"
      style="z-index: 9999"
      :url-list="[form.gmp]"
      :on-close="() => (showViewer3 = false)"
    />
    <!-- 申请状态成功  start -->
    <el-dialog :visible.sync="showStatus" width="500px" custom-class="dialog_view1" :show-close="false">
      <div class="PageHeader1 min-border-bottom flex j-between">
        <p class="p-left-20 weight">入驻申请成功</p>
        <!-- <p class="p-right-10 weight f16 cursor" style="color:#000" @click="() => showStatus = false">x</p> -->
      </div>
      <div style="height:auto" class="flex j-center a-center column">
        <div class="p-top-20">感谢您的入驻！</div>
        <div class="p-tb-10">后台管理员审核通过后，</div>
        <div class="p-bottom-20">会以短信形式将管理员账号和密码发送至您的手机</div>
      </div>
      <div class="footers min-border-top flex a-center j-center p-top-20">
        <el-button size="mini" type="primary" round @click="sureSubmit">确定</el-button>
      </div>
    </el-dialog>
    <!-- 申请状态成功  end -->
    <!-- 协议  start -->
    <el-dialog :close-on-click-modal="false" :visible.sync="isshowXieyi" :width="_isMobile() ? '80%' :'50%'" custom-class="dialog_view1" :show-close="false">
      <div class="PageHeader1 min-border-bottom flex j-between">
        <p class="p-left-20 weight">《平台服务协议》</p>
        <!-- <p class="p-right-10 weight f16 cursor" style="color:#000" @click="() => showStatus = false">x</p> -->
      </div>
      <div style="height:300px; overflow:auto" class="flex j-center a-center column">
        <div
          style="width:70%;margin:0 auto;line-height:180%;height:100vh;overflow:auto"
        >
          <h3 style="text-align:center;">用户服务协议</h3>
          <p style="text-indent:2em;">
            在您及您所在的公司使用“MAI47平台（www.mai47.com）”（后称“平台”）相关服务前，请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、法律适用和争议解决条款。如您在本平台进行注册或使用本平台，且已勾选用户服务协议，即被视为对本协议全部内容的认可和接受。当您登陆“MAI47平台”时，则表示您已接受经修订的“协议”条款，当您与“MAI47平台”及相关公司发生争议时，应以最新的“协议”条款为准。除另行明确声明外，任何使“服务”范围扩大或功能增强的新内容均受本协议约束。本协议内容包括协议正文及所有“MAI47平台”已经发布或将来可能发布的各类规则。所有规则为本协议不可分割的一部分，与本协议正文具有同等法律效力。
          </p>
          <br>
          <b>定义：</b>
          <br>
          平台，特指www.mai47.com中“秸瑞mai47医疗供应链协同平台”及上海秸瑞信息科技有限公司开发的mai47一系列软件、功能与工具。
          <br>
          用户，特指注册、登录、使用平台的法人机构及由法人授权的自然人用户。
          <br>
          服务，特指平台中所有的软件功能与软件工具。
          <br>
          上海秸瑞，特指上海秸瑞信息科技有限公司。
          <br>
          <b> 一、 协议的修改与更新</b>
          <br>
          <p style="text-indent:2em;">
            因互联网高速发展及运营“MAI47平台”需要，上海秸瑞信息科技有限公司（下称“本公司”）有权对本协议条款及“MAI47平台”的其他协议内容进行修改及增加其他协议，修改、增加后的协议与本协议不可分割且具有同等法律效力。您有义务及时关注并阅读最新版的协议、其他条款及网站公告。如您不同意接受更新后的协议，应立即停止接受“MAI47平台”依据本协议提供的服务；如您继续使用平台提供的服务的，即视为同意更新后的协议。如本协议的其中一条被视为无效的或因任何理由导致不可执行的，则该条款并不影响其他条款的可执行性及有效性。
          </p>
          <br>
          <b>
            二、注册使用
          </b>
          <br>
          1、您确认您开始注册“MAI47平台”个人账号及公司账号前，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力。如您不具备与您行为相适应的民事行为能力，则您及您的监护人应承担因此而导致的一切后果。
          <br>
          2、您在注册时，应确保准确地填写及上传个人信息（包括但不限于姓名、联系地址、联系电话及电子邮件地址等）与公司信息（包括但不限于公司名称、公司地址、公司电话、法定代表人及各类资质证件信息等），如果您及您的公司信息发生变动，应第一时间进行更新。您了解并同意，您有义务保持您提供信息的真实性及有效性。如您未能及时更新信息导致任何一方出现损失，应由您及您所述的公司承担全部责任及损失。如您停止使用账号，请第一时间通知平台或上海秸瑞关闭账号所有权限，在您未完成通知义务前所有损失由您的公司承担。
          <br>
          3、用户在注册完成后获得用户账户和密码。请务必妥善保管用户账号、登录密码，任何人凭用户密码登录用户账号或共享用户账户，均视为用户您的个人行为，由此导致的任何个人资料泄露及任何损失，本平台不负任何责任。账户因您泄露或因您遭受他人网络攻击、诈骗等行为导致的损失及后果，平台并不任何承担责任，您应通过司法、行政等救济途径向侵权行为人追偿。
          <br>
          4、您需确保您及您的公司机构不是任何国家、组织机构、国际组织或者地域实施的贸易限制、制裁或其他法律、规则限制的对象，否则您可能无法正常注册及使用本平台相关服务。此情况下您及您的公司机构进行的任何操作均属于您的个人行为，本平台不承担任何相关责任。
          <br>
          5、您及您的公司机构在注册成为MAI47平台用户后，您可通过MAI47平台进行特定免费功能的使用（具体功能内容以当前可使用功能为准，上海秸瑞拥有最终解释权）。但具体的功能及服务内容根据不同的用户类型有所不同，如您想获得进一步信息和服务，请与本公司进行进一步联络。
          <br>
          6、由于您的平台账户关联您的公司信息及平台商业信息，您的平台账户仅限您个人使用。未经您的公司及平台方同意，您直接或间接授权第三方使用您平台账户或获取您账户项下信息及操作的行为被认为是您所在公司所授权的行为，由您的公司承担所有责任。如我司根据平台规则中约定的违约认定程序及标准判断您平台账户的使用可能危及您的账户安全或平台信息安全的，平台可拒绝提供相应服务。
          <br>
          7、由于用户账户关联用户信用信息，仅当有法律明文规定、司法裁定或经平台同意，并符合平台规则规定的用户账户转让流程的情况下，您可进行账户的转让。您的账户一经转让，该账户项下权利义务一并转移。除此外，您的账户不得以任何方式转让，否则平台有权追究您的违约责任，且由此产生的一切责任均由您及您的公司承担。
          <br>
          8、作为平台运营者，为使您更好地使用平台的各项服务，保障您的账户安全，平台可要求您及您的公司依照我国法律规定完成资质认证及其他要求的内容。
          <br>
          9、在您注册成为平台用户后应与平台一起维护平台的名誉和利益，不得在论坛、微博、报纸及杂志等网络平台或媒体上发布诽谤、诬陷等对平台不利的信息和图片，您有义务举报对平台不利的相关言论和行为。如发现有此类行为，平台有权注销您的账户，并保留追究法律责任的权利。
          <br>
          10、您同意，在平台未向您收费的情况下，平台可自行全权决定以任何理由
          (包括但不限于平台认为您已违反本协议的字面意义和精神，或您以不符合本协议的字面意义和精神的方式行事，或您在超过90天的时间内未以您的帐号及密码登录网站)
          终止您的平台密码、帐户 (或其任何部份)
          或您对平台的使用，并删除和丢弃您在使用平台中提交的资料与信息。平台将在停用前1天以短信的方式通知您，方便您备份您的所有资料。通知完成后，平台默认您已收到该信息。您同意，在平台向您收费的情况下，平台网应基于合理的怀疑且经电子邮件通知的情况下实施上述终止服务的行为。平台同时可自行全权决定，在发出通知或不发出通知的情况下，随时停止提供服务或其任何部份。您同意，根据本协议的任何规定终止您使用服务之措施可在不发出事先通知的情况下实施，并承认和同意，平台可立即使您的帐户无效，撤销您的帐户以及在您的帐户内的所有相关资料和档案，同时可能禁止您进一步接入平台其他服务。帐号终止后，平台没有义务为您保留原帐号中或与之相关的任何信息，或转发任何未曾阅读或发送的信息给您或第三方。同时平台停止对于您的服务后，您应继续遵守平台服务协议中的其他条款。
          <br>
          <b>
            三、隐私政策
          </b>
          <br>
          1、平台尊重并保护所有使用网络服务用户的个人隐私权。为了给您提供更准确、更有个性化的服务，会按照本隐私权政策的规定使用和披露您的个人信息。但将以高度的勤勉、审慎义务对待这些信息。除本隐私权政策另有规定外，在未征得您事先许可的情况下，本产品不会将这些信息对外披露或向第三方提供。本产品会不时更新本隐私权政策。您在同意平台服务协议之时，即视为您已经同意本隐私权政策全部内容。本隐私权政策属于平台服务协议不可分割的一部分。
          <br>
          2、适用范围
          <br>
          a)在您注册平台帐号时，您根据平台要求提供的个人注册信息（商家应法律法规要求需公示的企业名称及相关工商注册信息除外）；
          <br>
          b)在您使用平台服务，或访问本平台网页时，本平台自动接收并记录的您的浏览器和iphone或者ipad上的信息，包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据；
          <br>
          c)本平台通过合法途径从商业伙伴处取得的用户个人数据。您了解并同意，以下信息不适用本隐私权政策：
          <br>
          d)您在使用本平台提供的搜索服务时输入的关键字信息
          <br>
          3、信息使用
          <br>
          a)本平台不会向任何无关第三方提供、出售、出租、分享或交易您的个人信息，除非事先得到您的许可，或该第三方和本平台（含平台及平台运营方关联公司）单独或共同为您提供服务，且在该服务结束后，其将被禁止访问包括其以前能够访问的所有这些资料。
          <br>
          b)本平台亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息。任何本平台用户如从事上述活动，一经发现，本平台有权立即终止与该用户或企业的服务。
          <br>
          c)为服务用户的目的，本平台可能通过使用您的个人信息，向您提供您感兴趣的信息，包括但不限于向您发出平台和服务信息，或者与本平台合作伙伴共享信息以便他们向您发送有关其平台和服务的信息。
          <br>
          4、信息披露
          <br>
          在如下情况下，本平台将依据您的个人意愿或法律的规定全部或部分的披露您的个人信息：
          <br>
          a)经您事先同意，向第三方披露；
          <br>
          b)为提供您所要求的平台和服务，而必须和第三方分享您的个人信息；
          <br>
          c)根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；
          <br>
          d)如您出现违反中国有关法律、法规或者本平台服务协议或相关规则的情况，需要向第三方披露；e)如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投
          诉人披露，以便双方处理可能的权利纠纷；
          <br>
          f)在本平台平台上创建的某一交易中，如交易任何一方履行或部分履行了交易义务并提出信息披露请求的，本平台有权决定向该用户提供其交易对方的联络方式等必要信息，以促成交易的完成或纠纷的解决。
          <br>
          g)其它本平台根据法律、法规或者网站政策认为合适的披露。
          <br>
          5、信息存储和交换
          <br>
          本平台收集的有关您的信息和资料将保存在本平台及（或）其关联公司的服务器上，这些信息和资料可能传送至您所在国家、地区或本平台收集信息和资料所在地的境外并在境外被访问、存储和展示。
          <br>
          6、Cookie的使用
          <br>
          a)在您未拒绝接受cookies的情况下，本平台会在您的手机或计算机上设定或取用cookies，以便您能登录或使用依赖于cookies的本平台平台服务或功能。本平台使用cookies可为您提供更加周到的个性化服务，包括推广服务。
          <br>
          b)您有权选择接受或拒绝接受cookies。您可以通过修改浏览器设置的方式拒绝接受cookies。但如果您选择拒绝接受cookies，则您可能无法登录或使用依赖于cookies的本平台网络服务或功能。
          <br>
          c)通过本平台所设cookies所取得的有关信息，将适用本政策。
          <br>
          7、信息安全
          <br>
          a)本平台帐号均有安全保护功能，请妥善保管您的用户名及密码信息。本平台将通过对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您注意在信息网络上不存在“完善的安全措施”。
          <br>
          b)在使用本平台网络服务进行网上交易时，您不可避免的要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或者邮政地址。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，尤其是本平台用户名及密码发生泄露，请您立即联络本产品客服，以便采取相应措施
          <br>
          c)本平台收集到的您在发布的有关信息数据，包括但不限于参与活动、成交信息及评价详情；
          <br>
          d)违反法律规定或违反规则行为及已对您采取的措施。
          <br>
          7.本隐私政策的更改
          <br>
          a）如果决定更改隐私政策，我们会在本政策中，以及我们认为适当的位置发布这些更改，以便您了解我们如何收集、使用您的个人信息，哪些人可以访问这些信息，以及在什么情况下我们会透露这些信息。
          <br>
          b）保留随时修改本政策的权利，因此请经常查看。如对本政策作出重大更改，会通过网站通知的形式告知。使用方披露自己的个人信息，如联络方式或者邮政地址。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，尤其是本应用用户名及密码发生泄露，请您立即联络平台客服，以便本应用采取相应措施。
          <br>
          <b>
            四、平台使用
          </b>
          <br>
          1、您完全了解平台上的信息系用户自行发布，且可能存在风险和瑕疵。平台仅作为第三方为用户提供各项服务，并显示由用户发布的各类信息。但是，平台不能控制平台所涉及的物品的质量、安全或合法性，商贸信息的真实性或准确性，以及平台用户履行其在贸易协议项下的各项义务的能力。平台不能也不会控制置换各方能否履行协议义务。此外，您应注意到，与外国国民、未成年人或以欺诈手段行事的人进行交易的风险是客观存在的。您应自行谨慎判断确定相关物品及/或信息的真实性、合法性和有效性，并自行承担因此产生的责任与损失。
          <br>
          2、您同意，您发布在平台网站上的任何“物品”（泛指一切可供依法置换的、有形的或无形的、以各种形态存在的某种具体的物品，或某种权利或利益，或某种票据或证券，或某种服务或行为。本协议中“物品”一词均含此义）
          <br>
          a）不会有欺诈成份，与售卖伪造或盗窃无涉；
          <br>
          b）不会侵犯任何第三者对该物品享有的物权，或版权、专利、商标、商业秘密或其他知识产权，或隐私权、名誉权等任何权利；
          <br>
          c）不会违反任何法律、法规、条例或规章
          (包括但不限于关于规范出口管理、贸易配额、保护消费者、不正当竞争或虚假广告的法律、法规、条例或规章)；
          <br>
          d）不会含有诽谤（包括商业诽谤）、恐吓或骚扰的内容；
          <br>
          e）不会含有淫秽、或包含任何儿童色情内容；
          <br>
          f）不会含有蓄意毁坏、恶意干扰、秘密地截取或侵占任何系统、数据或个人资料的任何病毒、伪装破坏程序、电脑蠕虫、定时程序炸弹或其他电脑程序；
          <br>
          g）不会直接或间接与下述各项货物或服务连接，或包含对下述各项货物或服务的描述：(i)
          本协议项下禁止的货物或服务；或您无权连接或包含的货物或服务。
          <br>
          h）此外，您同意不会：在与任何连锁信件、大量胡乱邮寄的电子邮件、滥发电子邮件或任何复制或多余的信息有关的方面使用“服务”；(i)
          未经其他人士同意，利用“服务”收集其他人士的电子邮件地址及其他资料；或 (ii)
          利用“服务”制作虚假的电子邮件地址，或以其他形式试图在发送人的身份或信息的来源方面误导其他人士。
          <br>
          3、您不得在平台公布或通过平台买卖：
          <br>
          a) 可能使平台违反任何相关法律、法规、条例或规章的任何物品；
          <br>
          b) 平台为应禁止或不适合通过本网站买卖的任何物品；
          <br>
          c）国家任何相关法律、法规、条例或规章禁止的任何物品。
          <br>
          4、平台不能亦不试图对其他用户通过平台提供的资料进行控制。就其本质而言，其他用户的资料，可能是令人反感的、有害的或不准确的，且在某些情况下可能带有错误的标识说明或以欺诈方式加上标识说明。希望您在使用平台时，小心谨慎并运用常识。
          <br>
          5、平台目前部分功能提供免费试用，本公司有权对平台进行升级及更新，自行设置收费的时间期限、软件功能、费率标准。您如果不同意本公司设置的软件功能、费率标准的，则应当立即停止使用平台；您如果继续使用的，则视为您接受本公司的上述设置，您应当遵照履行并向本公司支付相应的技术服务费用和注册费用。
          <br>
          6、当用户进行交易时，请仔细确认所购商品的名称、价格、数量、型号、规格、等信息是否与要求的一致。如若商品信息验收时，发现与需求产品不符时，应由交易双方协商沟通，并按照正常流程完成产品进行退货操作，直至彼此双方满意，平台不提供协商。本平台不对用户提供的商品信息承担任何责任。
          <br>
          7、使用平台进行产品订购，您应具备购买相关商品的权利能力和行为能力。在下订单的同时，即视为您满足上述条件，您需对您在订单中提供的所有信息的真实性负责。如您购买特定商品需满足特别资质的，您需提供相应的证明资料才能购买。
          <br>
          8、您应当遵守诚实信用原则，确保您所发布的商品及/或服务信息真实、与您实际所销售的商品或提供的服务相符，并在交易过程中切实履行您的交易承诺。
          您应当维护平台市场良性竞争秩序，不得贬低、诋毁竞争对手，不得干扰平台上其他用户进行的任何交易、活动，不得以任何不正当方式提升或试图提升自身的信用度，不得以任何方式干扰或试图干扰平台的正常运作。
          <br>
          9、您有权自行决定商品或服务的促销及推广方式，平台亦为您提供了形式丰富的促销推广工具。您的促销推广行为应当符合国家相关法律法规及平台的要求。
          <br>
          10、依法纳税是每一个公民、企业应尽的义务，您应对销售额/营业额超过法定免征额部分及时、足额地向税务主管机关申报纳税。
          <br>
          <b>五、知识产权</b>
          <br>
          1、您完全理解并同意不可撤销地授予平台及其关联公司下列权利，对于您提供的资料与信息，您授予平台及其关联公司独家的、全球通用的、永久的、免费的许可使用权利
          (并有权在多个层面对该权利进行再授权)，使平台及其关联公司有权(全部或部份地)
          使用、复制、修订、改写、发布、翻译、分发、执行和展示"您的资料"或制作其派生作品，和/或以现在已知或日后开发的任何形式、媒体或技术，将"您的资料"纳入其他作品内。
          同样，当您向平台关联公司作出任何形式的承诺，且相关公司已确认您违反了该承诺，则平台有权立即按您的承诺约定的方式对您的账户采取限制措施，包括但不限于中止或终止向您提供服务，并公示相关公司确认的您的违约情况。您了解并同意，平台无须就相关确认与您核对事实，或另行征得您的同意，且平台无须就此限制措施或公示行为向您承担任何的责任。
          <br>
          2、平台是本公司工作人员原创的作品，平台所有权公司及开发作者共同享有版权。如其他媒体、网站或个人从本网站下载、链接、或以其他形式的复制发表、使用，须取得本网站的书面授权，未经授权严禁转载或用于其它商业用途。
          <br>
          3、本公司对本网站的内容享有全部版权，包括：用户证件照、商品、服务标志、证件文字内容、软件图标已及计算机软件、标识、下载文件、数据汇编都是本公司或其内容提供者的财产，受到中华人民共和国版权相关法律法规的保护。用户对其仅享有本协议约定范围内的使用权。
          <br>
          4、您不得进行任何侵犯本平台知识产权的行为，或者进行其他的有损于本公司或其他用户合法权益的行为，包括但不限于：
          <br>
          a）删除或修改本平台上的版权信息；
          <br>
          b）修改、复制和/或发行本平台；
          <br>
          c）在本平台当中内置各种插件程序或者其他的第三方程序；
          <br>
          d）进行编译、反编译、反向工程或者以其他方式破解本平台的行为；
          <br>
          e）使用本平台外挂和/或利用本平台当中的BUG来获得不正当的利益；
          <br>
          f）利用劫持域名服务器等技术非法侵入、破坏本平台的服务器软件系统，或者修改、增加、删除、窃取、截留、替换本平台的客户端和/或服务器软件系统中的数据，或者非法挤占本平台的服务器空间，或者实施其他的使之超负荷运行的行为；
          <br>
          g）假冒本公司或用户在本平台当中发布任何诈骗或虚假信息；
          <br>
          h）利用本平台故意传播恶意程序或计算机病毒。
          <br>
          i）利用您对于本平台内容或功能的了解，向第三方进行透露。
          <br>
          如本公司发现您有任何侵犯本平台知识产权的行为，本公司保留通过法律途径维护知识产权的权利，本公司或平台产生的所有经济损失，由您及您的公司承担。
          <br>
          5、您不得侵犯他人知识产权或涉及第三方商业秘密及其他专有权利。如果有其他用户或权利人发现您发布的产品或信息涉嫌知识产权、或其他合法权益争议的，这些用户或权利人有权要求平台删除您发布的信息，或者采取其他必要措施予以制止，平台将会依法采取这些措施。
          <br>
          <b>六、免责申明</b>
          <br>
          1、平台系统因下列状况无法正常运作，使用户无法使用各项服务时，平台不承担损害赔偿责任，该状况包括但不限于：
          <br>
          a) 平台如因系统维护或升级而需暂停服务时，将事先公告；
          <br>
          b) 电信设备出现故障不能进行数据传输的。
          <br>
          c)
          因台风、地震、海啸、洪水、停电、战争、恐怖袭击等不可抗力之因素，造成本公司系统障碍不能执行业务的。
          <br>
          d)
          如若其他由于黑客攻击、计算机病毒侵入或发作、因政府管制而造成的暂时性关闭等影响网络正常经营的不可抗力而造成的损失或因线路及本公司控制范围外的硬件故障。
          <br>
          2、您与在平台上或通过平台物色的刊登广告人士通讯或进行业务往来或参与其推广活动，包括就相关货物或服务付款和交付相关货物或服务，以及与该等业务往来相关的任何其他条款、条件、保证或声明，仅限于在您和该刊登广告人士之间发生。您同意，对于因任何该等业务往来或因在平台上出现该等刊登广告人士而发生的任何种类的任何损失或损毁，平台无需负责或承担任何责任。您如打算通过平台创设或参与任何公司、股票行情、投资或证券有关的任何服务，或通过平台收取或要求与任何公司、股票行情、投资或证券有关的任何新闻信息、警戒性信息或其他资料，敬请注意，平台不会就通过传送的任何该等资料的准确性、有用性或可用性、可获利性负责或承担任何责任，且不会对根据该等资料而作出的任何置换或投资决策负责或承担任何责任。
          <br>
          3、平台不涉及用户间因交易而产生的法律关系及法律纠纷，不会且不能牵涉进交易各方的交易当中。倘若您与一名或一名以上用户，或与您通过平台网站获取其服务的第三者服务供应商发生争议，您免除平台(及平台代理人和雇员)
          在因该等争议而引起的，或在任何方面与该等争议有关的不同种类和性质的任何(实际和后果性的)
          权利主张、要求和损害赔偿等方面的责任。
          平台有权通过电子邮件等联系方式向您了解情况，并将所了解的情况通过电子邮件等方式通知第三方，您有义务配合平台的工作，否则平台有权做出对您不利的处理结果。
          经生效法律文书确认用户存在违法或违反本协议行为或者平台自行判断用户涉嫌存在违法或违反本协议行为的，平台有权在平台网中文网站上以网络发布形式公布用户的违法行为并做出处罚（包括但不限于限权、终止服务等）。
          <br>
          4、本公司平台上的商品信息均由产品供应商自行填写、自行发布，所以其真实性、准确性和合法性由用户负责。如信息错误影响人员对商品的使用，由相对应的平台用户承担责任。
          <br>
          <b>七、其他</b>
          <br>
          1、平台服务仅供能够根据相关法律订立具有法律约束力的合约的自然人、法人或其他组织使用。如不符合本项条件，请勿使用平台。平台可随时自行全权决定拒绝向任何服务对象提供服务。
          <br>
          2、不可抗力，对于因平台合理控制范围以外的原因，包括但不限于自然灾害、罢工或骚乱、物质短缺或定量配给、暴动、战争行为、政府行为、通讯或其他设施故障或严重伤亡事故等，致使平台服务延迟或未能履约的，平台不对您承担任何责任。
          <br>
          3、法律管辖和适用，本协议的订立、执行和解释及争议的解决均适用在中华人民共和国大陆地区适用之有效法律（但不包括其冲突法规则）。如缔约方就本协议内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，任何一方均可向本公司所在地人民法院提起诉讼。
          <br>
          4、本协议在您完成平台账户注册后开始执行，并长期有效。如您停止使用后，本协议任然有效。如您或您的公司与本公司签署关于平台的其他使用协议，本协议依然有效，如有冲突条款，则以双方协议中最新条款为准。
          <br>

        </div>
      </div>
      <div class="flex j-center a-center m-top-30 footers min-border-top flex a-center j-center p-top-20">
        <el-button type="primary" size=" mini" style="width:100px" @click="sureSubmitDoc">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 协议  end -->
  </div>
</template>
<script>
import Index from '@/minxin/settlein/index'
export default Index
</script>
<style lang="scss" scoped>
.settlein {
    width: 100%;
    // min-width: 1100px;
    height: 100%;
    overflow-x: auto;
    justify-content: space-between;
    .top_view_ {
       width: 100%;
       height: 70px;
        .image_veiw{
            width:  150px;
            height: 100%;
            background-image: url('../../static/logo.png');
            background-repeat: no-repeat;
            background-position: left 57px center;
        }
        .left{
            width: 50%;
            height: 100%;
        }
        .right {
            flex:1;
        }
    }
   .main_set {
    //  height:  calc(100% - 385px - 70px);
     min-height:calc(100% - 385px - 70px);
     display: flex;
     justify-content: center;
     .main {
       width: 80%;
       height: auto;
      .title {
        width: 100%;
        height: 40px;
        line-height: 40px;
        background-color: #ccc;
      }
      .form {
        width: 80%;
        height: auto; //  height: 100%;
        margin: 0 auto;
      }
      .btn {
        width: 120px;
        height: 30px;
        background-color: #3e76ed;
        border-radius: 50px;
        color: #fff;
        line-height: 30px;
        text-align: center;
      }
     }
   }
   .foot123 {
      // width: 100%;
      // min-width: 1200px;
      width: 100%;
      // max-width: max-content;
      background: #252730;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      // flex-direction: column;
      // overflow: auto;
   }
}
</style>
