// import SettleIntop from '@/components/settleIntop'
import SettleInFoot from '@/components/settleInFoot'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
// TextToCode
import {
  regionData, CodeToText, TextToCode
} from 'element-china-area-data'
const Index = {
  name: 'settleIn',
  components: {
    SettleInFoot,
    ElImageViewer
  },
  mounted() {
    this.GetValidateCodeImg()

    this.GetRegisterApplyVSBusScopeList()
    // this.form = this.$store.state.index.formJson
  },
  computed: {
    //  返回公司名称和社会统一信用代码是否都填写
    getComponeyInfo() {
      const flag = true
      if (this.form.SAPSTCD5 && this.form.CompanyName) {
        const reg = /^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/g
        if (reg.test(this.form.SAPSTCD5)) {
          this.getComponeyIsRegist()
        }
      }
      return flag
    }
  },
  watch: {
    getComponeyInfo() {
      // console.log('监听公入驻请求公司状态')
    }
  },
  data() {
    var validatePass = this.validatePass
    var validatePassCode = this.validatePassCode
    var validateChecked = this.validateChecked
    return {
      base: 'data: image/jpg; base64, /9j/4AAQSkZJRgABAQEAYABgAAD/2wBDAAgGBgcGBQgHBwcJCQgKDBQNDAsLDBkSEw8UHRofHh0aHBwgJC4nICIsIxwcKDcpLDAxNDQ0Hyc5PTgyPC4zNDL/2wBDAQkJCQwLDBgNDRgyIRwhMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjL/wAARCAAWADADASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD1x/sEdlDcG3eVo4o1P2eLO0sQGZifkOByd3KgE9xXIn4gWdm0NhN4U1e2kdkitzdaeVeckhQq7mbLdTlichT1PB6m0nuIbVZzvhcNh9++Uso2BWwpIVNpHGD94sdp3GuJ0KOfxB4x1LW3u2hj06ZrW2CxA4Y8zOCxCoScLu64OBzyOvDQp8s5VFdJX369vn+CuPyudDrXiWytVjs444INRu5YoraFbZHcNJ90sC3KZDZOOQOCCRjMvfElppV7cR32i6zMsISOS5t7P/R0XnB3l1VT83JYcEYOdtZt/pemS/EDRY7WyhS4YPqdxcyTBmkV/lXc2ckZYEYxgZ4PUT61cz+IvFWmaLG8f9nBVvb0QKyRGLhURiWIKli2flA+bocZM8kFypdVf+vuLjGHNeW1v6/Qv3firRrPTLC9uYNTea/hD22nRWgMyoOS5wF3A8Zy3Q8c5Nbo1rRdY0G3v7K2RrW7LIjphZUcE7PlXnadjE85AHzDbuK8dqOs/YfiH4judWuIreaz06NLVI5ArvwW/d55xknIyOOT0re8G6bDoHw+sra7tbg3kyu8iyIxMTtk5Ct0A+UEqDjIJ4ywxSd+ZrT/AIf8f68i5U4ximt9PndX+Vtv601RqUZtkgliMihkJY9QFA29+z5IxjoO5JqrutLeO3trOH7PZRMXkt7dFhSUn+8PmJ5wSc5JA98lFTtsYtWLcGoadbTNNFo1uszx+U0uQXZM52ltuSM9iarW9xBHd3N00ESXM8axPcrHukZU+5kkjLDPLd8DgYooouxEV4tleTx3MttbyXEILwGS1UiGTA2hTncq7vmJUqSfrxdtdRhttLazht1t42UjyokGxcpgqoG0KN2DnHPzcDPBRTSXLclSbdn/AFsf/9k=',
      options1: regionData,
      code_img: '',
      checked: false,
      selectedOptions: [],
      options: [
        {
          value: -1,
          label: '医疗器械经营企业（一类）'
        },
        {
          value: -2,
          label: '医疗器械经营企业（二类）'
        },
        {
          value: -3,
          label: '医疗器械经营企业（三类）'
        },

        {
          value: 1,
          label: ' 医疗器械生产企业 （一类）'
        },
        {
          value: 2,
          label: '医疗器械生产企业 （二类）'
        },
        {
          value: 3,
          label: '医疗器械生产企业 （三类）'
        },
        {
          value: 6,
          label: '非医疗器械经销商'
        },
        {
          value: 4,
          label: '药品类体外诊断试剂'
        }
      ],
      showViewer: false,
      showViewer1: false, // 医疗器械经营许可证
      showViewer2: false, // 药品生产许可证
      showViewer3: false, // 药品GMP证书
      form: {
        selectedOptions: [],
        Province: '',
        City: '',
        Area: '',
        CompanyName: '', // 企业名称
        CompanyTypeID: 1, //  4. 卖家 1. 买家
        SAPSTCD5: '', //  社会统一信用代码 91320594MA1QF8UC59
        Adress: '', // 单位地址
        AuthUserName: '', // 联系人
        PhoneNumber: '', // 联系方式
        PhoneValid: '', // 短信验证码
        delivery: false,
        BusinessScope: '', // 客户经营范围
        IDX: 0,
        Date: [],
        checked: false,
        YYZZStartDate: '', //  营业执照 有效期  开始日期
        YYZZEndDate: '', //  营业执照 有效期  结束日期
        YYZZIsLong: false, // 营业执照 有效期  是否长期
        YYZHUrl: '', // 营业执照 地址
        Remark: '', // 备注
        CertificateStr: [], // 执照信息
        certificateList: [], // 返回的执照信息
        code: '', // 图形验证码
        urlph: '', // 医疗器械经营许可证
        gmp: '', // 药品GMP证书
        yaopxk: '' // 药品生产许可证
      },
      label_value: '：',
      fileData: {
        FileName: 'phalange004.jpg',
        Base64String: ''
      },
      Guid: '',
      loading: false,
      isFlag: true, // 公司提示信息
      rules: {
        Adress: [
          {
            required: true,
            validator: async(rule, value, callback) => {
              if (!value) {
                callback(new Error('请输入公司详细地址'))
              }
              callback()
            },
            trigger: ['blur', 'change']
          }
        ],
        selectedOptions: [
          {
            required: true,
            validator: async(rule, value, callback) => {
              console.log(value)
              if (!value) return callback()
              if (value.length <= 0) {
                callback(new Error('请选择公司地址'))
              }
              callback()
            },
            trigger: ['blur', 'change']
          }
        ],
        PhoneNumber: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: validatePass, trigger: ['blur', 'change'] }
        ],
        CompanyName:
        // { required: true, message: '请输入公司名称', trigger: 'blur' },

          [{
            required: true,
            validator: async(rule, value, callback) => {
              if (!value) {
                callback(new Error('请输入公司名称'))
              }
              if (value && this.form.SAPSTCD5) {
                if (this.isSubmit) return
                const info = await this.getComponeyIsRegist()
                if (info.code === '10') {
                  // callback(new Error(info.desc))
                  if (this.isFlag) {
                    this.notify = this.$notify({
                      title: '状态',
                      message: info.desc + '驳回原因：' + info.Reason,
                      duration: 0,
                      type: 'warning',
                      onClose: () => {
                        this.isFlag = true
                      }
                    })
                    this.isFlag = false
                  }
                  callback()
                }
                if (info.code !== '0' && info.code !== '10') {
                  callback(new Error(info.desc))
                }
              }
              callback()
            },
            trigger: ['blur', 'change']
          }],
        SAPSTCD5:
          [{
            required: true,
            validator: async(rule, value, callback) => {
              if (!this.form.SAPSTCD5) {
                callback(new Error('请输入社会信用统一代码'))
              }
              if (this.form.SAPSTCD5) {
                if (this.form.SAPSTCD5.length !== 18) callback(new Error('请输入正确的18位社会信用统一代码'))
                // const regNumber = /\d+/ // 验证0-9的任意数字最少出现1次。
                // const regString = /[A-Z]+/ // 验证大小写26个字母任意字母最少出现1次。
                const reg = /^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/g
                // 验证第一个字符串
                if (reg.test(this.form.SAPSTCD5)) {
                  if (this.form.CompanyName) {
                    if (this.isSubmit) return
                    const info = await this.getComponeyIsRegist()
                    if (info.code === '10') {
                      // callback(new Error(info.desc))
                      if (this.isFlag) {
                        this.notify = this.$notify({
                          title: '状态',
                          message: info.desc + '驳回原因：' + info.Reason,
                          duration: 0,
                          type: 'warning',
                          onClose: () => {
                            this.isFlag = true
                          }
                        })
                        this.isFlag = false
                      }
                      callback()
                    }
                    if (info.code !== '0' && info.code !== '10') {
                      callback(new Error(info.desc))
                    }
                  }
                  console.log('str1：验证成功')
                } else {
                  callback(new Error('请输入正确的社会信用统一代码'))
                }
              }
              callback()
            },
            trigger: ['blur', 'change']
          }],
        // { validator: validatePass, trigger: ['blur', 'change'] }
        Date: [
          { required: true, message: '请输入证件有效期', trigger: ['blur', 'change'] }
        ],
        CompanyTypeID: [
          { required: true, validator: async(rule, value, callback) => {
            // callback(new Error('请输入社会信用统一代码'))
            if (this.form.CompanyTypeID === 4) {
              if (!this.form.BusinessScope) {
                return callback(new Error('请选择供应商类别'))
              }
              return callback()
            }
            callback()
          }, trigger: ['blur', 'change'] }
        ],
        AuthUserName: [
          { required: true, message: '请输入联系人', trigger: ['blur', 'change'] }
        ],
        PhoneValid: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error('请输入短信验证码'))
              } else if (value && value.length !== 6) {
                callback(new Error('请输入六位短信验证码'))
              }
              callback()
            },
            trigger: ['blur', 'change']
          }
        ],
        YYZHUrl: [
          { required: true, message: '请上传营业执照', trigger: ['blur', 'change'] }
        ],
        urlph: [
          { required: true, message: '请上传有效证件', trigger: ['blur', 'change'] }
        ],
        yaopxk: [
          { required: true, message: '请上传药品生产许可证', trigger: ['blur', 'change'] }
        ],
        gmp: [
          { required: true, message: '请上传药品GMP证书', trigger: ['blur', 'change'] }
        ],
        code: [
          { required: true, message: '请输入效验码', trigger: 'blur' },
          { validator: validatePassCode, trigger: ['blur', 'change'] }
        ],
        checked: [
          {
            validator: validateChecked,
            trigger: ['blur', 'change']
          }
        ]
      },

      CertificateType: null,
      codeNumLast: 0,
      statusCode: '',
      statusCode2: false,
      showStatus: false,
      isBoHui: false, // 是否是驳回的公司
      isSubmit: false,
      isshowXieyi: false, // 协议弹窗
      testFome: { CompanyName: '', SAPSTCD5: '' },
      notify: null
    }
  },
  methods: {
    _isMobile() {
      const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
    },
    login() {
      // this.$router.push('/')
      window.location.href = this.$Url
    },
    handleChange(value) {
      this.form.Province = CodeToText[value[0]]
      this.form.City = CodeToText[value[1]]
      this.form.Area = CodeToText[value[2]]
    },
    // 省市区填充显示
    setAddress(Province, City, Area) {
      const province = Province
      const cityq = City
      const area = Area
      console.log(Province, City, Area)
      this.form.selectedOptions = [TextToCode[province].code, TextToCode[province][cityq].code, TextToCode[province][cityq][area].code]
      console.log('this.selectedOptions ', this.selectedOptions)
    },
    // 查询公司是否存在
    async  getComponeyIsRegist() {
      const info = { desc: '', code: null, Reason: '' }
      const res = await this.$api.ValidRegistCompany({
        companyName: this.form.CompanyName,
        code: this.form.SAPSTCD5
      })
      if (res.RetCode !== '0' && res.RetCode !== '10' && res.RetCode !== '-1') return this.$message.error('错误：' + res.RetMsg)
      if (res.RetCode === '0') {
        info.desc = '该公司可以入驻'
        info.code = '0'
        this.isBoHui = false
        this.statusCode = ''

        // this.form.yaopxk = ''
        // this.form.gmp = ''
        // this.form.urlph = ''
        if (this.statusCode2) {
          // this.selectedOptions = []
          this.form.selectedOptions = []
          for (const key in this.form) {
            if (key !== 'CompanyName' && key !== 'SAPSTCD5') {
              this.form[key] = ''
            }
          }
          this.notify.close()
          this.statusCode2 = false
        }
      }
      if (res.RetCode === '10') {
        info.desc = '该公司已被驳回'
        info.code = '10'
        info.Reason = res.Message.Reason
        this.isBoHui = true
        this.statusCode = ''
        this.statusCode2 = true
        // 提交时不做表单初始化
        if (!this.isSubmit) {
          this.setAddress(res.Message.Province, res.Message.City, res.Message.Area)
          for (const key in this.form) {
            // [TextToCode[province].code, TextToCode[province][cityq].code, TextToCode[province][cityq][area].code]
            this.form[key] = res.Message[key]

            if (key === 'YYZHUrl') {
              this.form.YYZHUrl = this.b2bstaticUrl + '/' + this.form.YYZHUrl
            }
            if (key === 'BusinessScope') {
              this.form['BusinessScope'] = res.Message['BusinessScope'] * 1
              this.CertificateType = this.form['BusinessScope']
            }
            this.form.Date = []
            this.form.Date = [this.form.YYZZStartDate, this.form.YYZZEndDate]
            // console.log("this.form['certificateList']", this.form['certificateList'])
            // console.log(" this.form['certificateList']", this.form['certificateList'])
            if (key === 'certificateList' && this.form['certificateList'].length > 0) {
              // 医疗器械经营许可证
              if (this.form.CompanyTypeID === 4 && this.form.BusinessScope !== 4 && this.form.BusinessScope !== -1 && this.form.BusinessScope !== 6 && this.form.BusinessScope !== '') {
                this.form['certificateList'].map(item => {
                  if (item.TypeID === 15 || item.TypeID === 6 || item.TypeID === 2) {
                    this.$nextTick(() => {
                      this.form.urlph = this.b2bstaticUrl + '/' + item.FileUrl
                      this.label_value = item.CertificateName + '：'
                    })
                  }
                })
              }
              // 药品生产许可证  &&  GMP证书
              if (this.form.CompanyTypeID === 4 && this.form.BusinessScope === 4) {
                this.form['certificateList'].map(item => {
                  if (item.TypeID === 7) {
                    this.form.yaopxk = this.b2bstaticUrl + '/' + item.FileUrl
                  }
                  if (item.TypeID === 11) {
                    this.form.gmp = this.b2bstaticUrl + '/' + item.FileUrl
                  }
                })
              }
            }
          }
          this.setAddress(this.form.Province, this.form.City, this.form.Area)
        }
      }
      if (res.RetCode === '-1') {
        info.desc = res.RetMsg
        info.code = '-1'
        this.isBoHui = false
        this.statusCode = '-1'
        // this.selectedOptions = []
        // for (const key in this.form) {
        //   if (key !== 'CompanyName' && key !== 'SAPSTCD5') {
        //     this.form[key] = ''
        //   }
        // }
      }
      return info
    },
    isChangeValue(e) {
      // console.log(e)
      switch (e) {
        case 1:
          this.CertificateType = 15
          this.label_value = '第一类医疗器械生产备案凭证：'
          this.form.yaopxk = ''
          this.form.gmp = ''
          return
        case 2:
          this.CertificateType = 6
          this.label_value = '医疗器械生产许可证：'
          this.form.yaopxk = ''
          this.form.gmp = ''
          return
        case 3:
          this.CertificateType = 6
          this.label_value = '医疗器械生产许可证：'
          this.form.yaopxk = ''
          this.form.gmp = ''
          return
        case -1:
          this.label_value = '无：'
          this.form.urlph = ''
          this.form.yaopxk = ''
          this.form.gmp = ''
          return
        case -2:
          this.CertificateType = 2
          this.label_value = '医疗器械经营许可证/备案凭证：'
          this.form.yaopxk = ''
          this.form.gmp = ''
          return
        case -3:
          this.CertificateType = 2
          this.label_value = '医疗器械经营许可证/备案凭证：'
          this.form.yaopxk = ''
          this.form.gmp = ''
          return
        case 4:
          this.label_value = '无：'
          this.form.urlph = ''
          return
        case 6:
          this.label_value = '无：'
          this.form.urlph = ''
          this.form.yaopxk = ''
          this.form.gmp = ''
          return
        default:'测试：'
      }
    },
    // 经营范围
    GetRegisterApplyVSBusScopeList() {
      this.$api.GetRegisterApplyVSBusScopeList().then(res => {
        this.options = res.Message
      })
    },
    // 超出数量
    onExceed() {
      return this.$message.error('请先删除已上传文件')
    },
    uploadFile(params) {
      const _file = params.file
      const isLt2M = _file.size / 1024 / 1024 < 5
      this.$refs['uploadUrlRF'].clearFiles()

      if (!isLt2M) return this.$message.error('请上传5M以下的文件')

      // 通过 FormData 对象上传文件
      const formData = new FileReader()
      formData.readAsDataURL(_file)
      formData.onload = (e) => {
        this.fileData.FileName = _file.name
        this.fileData.Base64String = e.target.result
        this.$api.UploadRegistCertificate(this.fileData).then(res => {
          if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
          this.$message.success('上传成功，正在加载图片...')
          this.form.YYZHUrl = res.Message // 营业执照地址
        }).catch(err => {
          return this.$message.error('错误：' + err)
        })
      }
    },
    uploadFile1(params) {
      const _file = params.file
      const isLt2M = _file.size / 1024 / 1024 < 5
      this.$refs['uploadUrlRF1'].clearFiles()

      if (!isLt2M) return this.$message.error('请上传5M以下的文件')

      // 通过 FormData 对象上传文件
      const formData = new FileReader()
      formData.readAsDataURL(_file)
      formData.onload = (e) => {
        this.fileData.FileName = _file.name
        this.fileData.Base64String = e.target.result
        this.$api.UploadRegistCertificate(this.fileData).then(res => {
          if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
          this.$message.success('上传成功，正在加载图片...')
          this.form.urlph = res.Message
        }).catch(err => {
          return this.$message.error('错误：' + err)
        })
      }
    },
    uploadFile2(params) {
      const _file = params.file
      const isLt2M = _file.size / 1024 / 1024 < 5
      this.$refs['uploadUrlRF2'].clearFiles()

      if (!isLt2M) return this.$message.error('请上传5M以下的文件')

      // 通过 FormData 对象上传文件
      const formData = new FileReader()
      formData.readAsDataURL(_file)
      formData.onload = (e) => {
        this.fileData.FileName = _file.name
        this.fileData.Base64String = e.target.result
        this.$api.UploadRegistCertificate(this.fileData).then(res => {
          if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
          this.$message.success('上传成功，正在加载图片...  this.form.yaopxk')
          this.form.yaopxk = res.Message
        }).catch(err => {
          return this.$message.error('错误：' + err)
        })
      }
    },
    uploadFile3(params) {
      const _file = params.file
      const isLt2M = _file.size / 1024 / 1024 < 5
      this.$refs['uploadUrlRF3'].clearFiles()

      if (!isLt2M) return this.$message.error('请上传5M以下的文件')

      // 通过 FormData 对象上传文件
      const formData = new FileReader()
      formData.readAsDataURL(_file)
      formData.onload = (e) => {
        this.fileData.FileName = _file.name
        this.fileData.Base64String = e.target.result
        this.$api.UploadRegistCertificate(this.fileData).then(res => {
          if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
          this.$message.success('上传成功，正在加载图片...')
          this.form.gmp = res.Message
        }).catch(err => {
          return this.$message.error('错误：' + err)
        })
      }
    },
    // 上传文件前
    beforeUpload(file) {

    },
    validateChecked(rule, value, callback) {
      if (!this.form.checked) {
        callback(new Error('请勾选协议'))
      }
      if (value) {
        callback()
      }
    },
    // 验证手机号
    validatePass(rule, value, callback) {
      if (!value) {
        callback(new Error('请输入手机号'))
      } else if (!this.$minCommon.checkMobile(value)) {
        callback(new Error('请输入正确的手机号'))
      }
      callback()
    },
    // 验证验证码
    validatePassCode(rule, value, callback) {
      if (!value) {
        callback(new Error('请输入验证码'))
      } else if (value && value.length !== 4) {
        callback(new Error('请输入正确的图形验证码'))
      }
      callback()
    },
    changeImg() {
      this.GetValidateCodeImg()
    },
    GetValidateCodeImg() {
      this.$api.GetValidateCodeImg().then(res => {
        if (res.RetCode !== '0') return this.$message.error(res.RetMsg)
        this.code_img = `data: image/jpg; base64, ${res.Message.Bytes}`
        this.Guid = res.Message.Guid
        const img = document.getElementById('code_img')
        img.onload = function() {
          const myCanvas = document.getElementById('myCanvas').getContext('2d')
          myCanvas.drawImage(img, 0, 0)
        }
      })
    },
    sureSubmit() {
      // this.$router.push('/login')
      this.login()
      this.isSubmit = false
    },
    sureSubmitDoc() {
      this.isshowXieyi = false
    },
    viewdoc() {
      this.isshowXieyi = true
      // this.$store.dispatch('index/setJson', this.form)
      // this.$router.push({ name: 'doc' })
    },
    allin(formName) {
      this.isSubmit = true
      this.$refs[formName].validate((valid) => {
        if (!valid) return false
        if (this.form.CompanyTypeID === 4) {
          if (!this.form.BusinessScope) {
            this.$message.info('请选择供应商类别')
            return false
          }
        }

        this.loading = true

        this.setCertificateTime()
        // 处理多个证件
        if (this.isBoHui) {
          this.setCertificateStrUpdate()
        }
        this.setCertificateStr(this.isBoHui)
        this.form.YYZZIsLong ? this.form.YYZZIsLong = 1 : this.form.YYZZIsLong = 0
        // 表单提交
        this.$api.EditRegisterApplyInfo(this.form).then(res => {
          this.loading = false
          // console.log(res)
          if (res.RetCode !== '0') {
            this.isSubmit = false
            return this.$message.error('错误：' + res.RetMsg)
          }
          this.showStatus = true
        }).catch(err => {
          this.isSubmit = false
          this.loading = false
          return this.$message.error('错误：' + err)
        })
        // console.log(this.form)
      })
    },
    isLong(a) {
      if (a) {
        this.form.Date = [this.form.Date[0] ? this.form.Date[0] : this.$minCommon.formatDate(new Date(Date.now()), 'yyyy-MM-dd'), '9999-12-31']
        return
      }
      this.form.Date = []
    },
    // 处理证件时间
    setCertificateTime() {
      // if (this.form.YYZZIsLong) {
      //   this.form.YYZZStartDate = ''
      //   this.form.YYZZEndDate = ''
      // }
      if (this.form.Date.length > 0) {
        this.form.YYZZStartDate = this.form.Date[0]
        this.form.YYZZEndDate = this.form.Date[1]
      }
    },
    // 处理证件方法
    setCertificateStr(isBoHui) {
      if (isBoHui && !this.statusCode2) {
        return
      }
      this.form.CertificateStr = []
      if (this.form.CompanyTypeID === 4) {
        if (this.form.urlph) {
          this.form.CertificateStr.push({
            FileUrl: this.form.urlph,
            type: this.CertificateType,
            CertificateName: this.label_value.split('：')[0],
            IDX: 0 // 暂时为0
          })
        }
        // 药品生产/经营许可证（7），药品GMP证书（11）
        if (this.form.yaopxk) {
          this.form.CertificateStr.push({
            FileUrl: this.form.yaopxk,
            type: 7,
            CertificateName: '药品生产/经营许可证',
            IDX: 0 // 暂时为0
          })
        }
        if (this.form.gmp) {
          this.form.CertificateStr.push({
            FileUrl: this.form.gmp,
            type: 11,
            CertificateName: '药品GMP证书',
            IDX: 0 // 暂时为0
          })
        }
      }
      this.form.CertificateStr = JSON.stringify(this.form.CertificateStr)
    },
    //  驳回的公司 更新  处理多个证件方法
    setCertificateStrUpdate() {
      this.form.CertificateStr = []
      if (this.form.CompanyTypeID === 4) {
        this.form.certificateList.map(item => {
          if (item.TypeID === 15 || item.TypeID === 6 || item.TypeID === 2) {
            item.FileUrl = this.form.urlph
          }
          if (item.TypeID === 7) {
            item.FileUrl = this.form.yaopxk
          }
          if (item.TypeID === 11) {
            item.FileUrl = this.form.gmp
          }
        })
      }
      this.form.CertificateStr = JSON.stringify(this.form.certificateList)
    },
    // 获取短信验证码
    SendPhoneValidataCode() {
      if (this.codeNumLast > 0) return
      if (!this.$minCommon.checkMobile(this.form.PhoneNumber)) {
        this.form.PhoneNumber = ' '
        setTimeout(() => {
          this.form.PhoneNumber = ''
        }, 20)
        return
      }
      if (this.form.code.length !== 4) {
        this.form.code = ' '
        setTimeout(() => {
          this.form.code = ''
        }, 20)
        return
      }
      if (!this.Guid) {
        return this.$message.error('请重新获取图形验证码')
      }
      this.$api.SendPhoneValidataCode({
        telNumber: this.form.PhoneNumber,
        validateCode: this.form.code,
        validCodeGuid: this.Guid
      }).then(res => {
        if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
        this.$minCommon.setCountDown((num, timer) => {
          this.codeNumLast = num
        })
        // console.log(res)
      })
    }
  }
}
export default Index
